
import { ethers } from 'ethers'
import contractToken from './contractToken'
import contractNFT from './contractNFT'
import contractWaKuang from './contractWaKuang'

const contractList = {
  Token: contractToken,
  NFT: contractNFT,
  MINT: contractWaKuang,
}


const GetWalletAddress = () => {
  return new Promise(async resolve => {


    if (typeof window.ethereum !== "undefined") {
      let addr = await ethereum.request({ method: "eth_requestAccounts" }); //授权连接钱包
      console.log("用户钱包地址:", addr[0]);
      resolve(addr[0])
    } else {
      alert("未安装钱包插件！");
    }
  })

}

/* 获取当前网络ID */
const GetCurrentChainId = () => {
  return new Promise(async resolve => {

    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      resolve(network)
    } else {
      alert("未安装钱包插件！");
    }
  })

}


// 
const abi = async (data, callback) => {

  const contractToken = contractList[data.contractKey]

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const useAccount = await signer.getAddress()


  // console.log(omg_contract.methods);

  // const bamount = (ethers.BigNumber.from(Number(data).toFixed(0))).mul('1000000000000000000')

  let contractTokenAdress = contractToken.address
  
  if (data.type === 'check') {
    const lp = new ethers.Contract(
      contractTokenAdress,
      contractToken.abi,
      signer
    )

    try {
      await lp[data.abiName](...(data.params || [])).then(res => {
        console.log(res);
        callback({
          code: 200,
          message: '',
          data: res
        })
      })
    } catch (err) {
      console.log(`请求失败${data.abiName}`, err);
      callback({
        code: 401,
        message: '取消操作'
      })
    }
  } else {
    /**
     * 
     * 200 成功
     * 
     * 401 取消操作
     * 
     * 400 操作失败
     */
    // alert('暂未开放!')
    // return 

    var Web3 = require('web3');
    var web3 = new Web3(Web3.givenProvider)
    const omg_contract = new web3.eth.Contract(contractToken.abi, contractTokenAdress)

    let sendData = {
      from: useAccount
    }

    if (data.sendValue) sendData['value'] = data.sendValue;
    
    console.log(data)

    omg_contract.methods[data.abiName](...data.params || []).send(sendData)
      .on('transactionHash', function (hash) {
        console.log('hash', hash);
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt);
        callback({
          code: 200,
          data: receipt,
          message: ''
        })
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        console.log('confirmationNumber', confirmationNumber);
        console.log('receipt', receipt);
      })
      .on('error', function (err) {
        console.log(err)
        callback({
          code: 401,
          message: '取消操作'
        })
      });
  }

}


export {
  GetWalletAddress,
  abi,
  ethers
}