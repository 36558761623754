<style lang="scss" scoped>
.Mystery-Box {
  margin-top: 20vh;
  padding-bottom: 100px;

  .page-home__btn {
    text-align: center;
    padding: 40px 0 10px;
  }

  .Mystery-Box__image {
    text-align: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }

  .module-block {
    height: auto;
    // height: 70vh;
    overflow-y: auto;
    padding: 2vh 2vw;
    background-color: rgba(0, 0, 0, 0.8);

    .module-block__cell {
      margin-bottom: 30px;

      .page-home__btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;
      }

      .module-btn {
        margin: 0 10px;
      }
    }
  }

  .module-block__box {
    position: relative;
    padding-top: 20px;
  }

  .Mystery-Box__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 14vh;
    left: 0px;
    z-index: 1;

    >div {
      width: 60px;
      line-height: 30px;
      text-align: center;
      // border-radius: 4px;
      overflow: hidden;
      background-color: #000;
      color: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all .3s;

      &:nth-of-type(2) {
        margin-left: -2px;
      }

      &.active {
        color: #fff;
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }
}
</style>


<template>
  <div class="Mystery-Box">
    <div class="module-block">
      <div class="Mystery-Box__tabs">
        <div class="NFT" :class="{ active: active == 0 }" @click="active = 0">代币</div>
        <div class="Token" :class="{ active: active == 1 }" @click="active = 1">挖矿</div>
      </div>

      <TokenModule v-if="active == 0"></TokenModule>

      <WaKunagModule v-else-if="active == 1"></WaKunagModule>

    </div>
  </div>
</template>

<script>

import TokenModule from './components/Token'
import WaKunagModule from './components/WaKuang'
export default {
  name: "Mystery-Box",
  components: {
    TokenModule,
    WaKunagModule
  },
  data() {
    return {
      active: 1,
    };
  },

};
</script>
