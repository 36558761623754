import store from '@/store'
import { ethers } from 'ethers'
import contractToken from '@/views/MINT/js/contract'


// 1.通过私钥获取地址
const GetAddressForPrivateKey = (privateKey) => {
  return new Promise(resolve => {

    let url = store.state.url
    let provider = new ethers.providers.JsonRpcProvider(url);
    let wallet = new ethers.Wallet(privateKey, provider);

    resolve(wallet)
  })
}



// 2.abi方法
const abi = async (privateKey, data, url) => {

  // if (!window.ethereum) return
  // await window.ethereum.enable()

  return new Promise(async (resolve) => {

    //合约地址

    const contractAddress = contractToken.address
    const contractAbi = contractToken.abi


    // 获取钱包数据
    const provider = new ethers.providers.JsonRpcProvider(url);
    const wallet = new ethers.Wallet(privateKey, provider);

    //定义合约
    const contractWithSigner = new ethers.Contract(contractAddress, contractAbi, wallet)

    try {

      const request = await contractWithSigner[data.abiName](...(data.params || []))
      console.log('等待')
      setTimeout(() => {
        resolve({
          code: 402,
          message: 'fail',
          data: false
        })
      }, 25 * 1000)
      await request.wait();
      resolve({
        code: 200,
        message: '',
        data: request
      })
    } catch (error) {
      console.log('请求出错1');
      console.log(data.abiName, error)
      resolve({
        code: 401,
        message: 'fail',
        data: false
      })
    }

  })

}

export {
  GetAddressForPrivateKey,
  abi,
}