<template>
    <div class="box">
        <h1>定时执行</h1>
        <div style="height: 60px;">
            <div v-if="!timer" style="margin-bottom: 10px;">
                <button @click="startNow" style="margin-right: 8px;">开始(立即执行第一次)</button>
            </div>
            <div v-if="!timer">
                <button @click="startDeley" style="margin-right: 8px;">开始(24小时之后执行第一次)</button>
            </div>
            <div v-if="timer" style="margin-bottom: 10px;">
                <button @click="pause">暂停</button>
            </div>
        </div>

        <div class="table-box">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>执行时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(time, index) in executionTimes" :key="index">
                        <td>{{ (executionTimes.length - index) }}</td>
                        <td>{{ time }}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>
  
<script>
import { abi } from './contractMethodsPrivateKey'
export default {
    data() {
        return {
            timer: null,
            executionTimes: [],
        };
    },
    methods: {
        startNow() {
            this.pause(); // 确保没有重复的定时器
            this.doReward(); // 立即执行一次
            this.start()
        },
        startDeley() {
            this.pause(); // 确保没有重复的定时器
            this.start()
        },
        start() {

            const timeData = 24 * 60 * 60 * 1000
            // const timeData = 6 * 1000
            this.timer = setInterval(this.doReward, timeData); // 每24小时执行一次
        },
        pause() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        async doReward() {
            const now = new Date();
            const formattedTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
            this.executionTimes.unshift(formattedTime);

            await this.SetDoReward()
        },
        SetDoReward() {

            const privateKey = '6d0b00553c7d707bde954a9df5b366f645acf4b4eebf138c5a316a0d17563247'
            const url = ' https://data-seed-prebsc-1-s1.bnbchain.org:8545'
            
            return new Promise( async resolve => {

                const State = await abi(privateKey, {
                    abiName: 'doReawrd',
                    params: [],
                }, url)
                if (State.code == 200) {
                    console.log('State', State.data)
                    resolve(true)
                } else {
                    resolve(0)
                }
            })
            
        }
    },
    beforeDestroy() {
        this.pause(); // 组件销毁时清除定时器
    },
};
</script>
  
<style>
.box {
    margin: 0px auto;
    width: 95%;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    top: 80px;
}

.table-box {
    max-height: 55vh;
    overflow-y: auto;
}

/* 添加一些基本样式 */
table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}
</style>
  